

































import { defineComponent } from "@/composition-api";
import UiDialog from "@dpa-id-components/ui-dialog";
import "@dpa-id-components/ui-dialog/dist/UiDialog.css";
import UiIcon from "@dpa-id-components/ui-icon";

export default defineComponent({
  components: {
    UiDialog,
    UiIcon
  }
});
